<template>
<docs-layout>
  <div  class="text-left space-y-4 mb-10">
    <p>Default</p>
    <asom-tooltip tooltip-text="Tooltip content (prop)" />
    <br/>
    <asom-tooltip>
      <p class="asom-p">Tooltip content (slot)</p>
    </asom-tooltip>
    <p>With toggle text</p>
    <asom-tooltip tooltip-text="Tooltip content (prop)" placement="bottom" toggle-text="Show tooltip (prop)" />
    <br/>
    <asom-tooltip>
      <template #toggleText>
        <span>Show tooltip (slot)</span>
      </template>
      <p class="asom-p">Tooltip content (slot)</p>
    </asom-tooltip>
  </div>
</docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {}
  }
}
</script>